import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import scrollToElement from 'scroll-to-element';

import { Container, Inner } from './Elements';
import { heroTypes } from '../types/propTypes';
import DownSrc from '../images/down.svg';
import mq from '../style/mq';
import useTheme from '../hooks/useTheme';

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  position: relative;
  ${mq.tabletSmall`
  min-height: 96vh;
  `}
  ${Container} {
    width: 100%;
    height: 100vh;
    ${mq.tabletSmall`
      height: 96vh;
    `}
  }
  ${Inner} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 4rem;

    ${mq.tabletSmall`
      padding: 2rem;
    `}
  }
  button {
    height: 4rem;
    width: 4rem;
    position: absolute;
    bottom: 2rem;
    left: calc(50% - 2rem);
    padding: 0;
    border: none;
    background: transparent;
    z-index: 10;
  }
`;

const TextImageWrapper = styled.div`
  width: 64%;
  padding-bottom: 8%;
  img {
    width: 100%;
  }
  ${mq.tabletSmall`
  width: 90%;
  padding-bottom: 20%;
  `}
`;

export default function Hero({
  isMobile,
  background,
  backgroundMobile,
  textImage,
  pathname,
}) {
  const theme = useTheme(pathname);
  return (
    <BackgroundImage
      fluid={isMobile ? backgroundMobile : background}
      backgroundColor={theme && theme.color}
    >
      <Wrapper>
        <Container>
          <Inner>
            <TextImageWrapper>
              <img src={textImage} alt="Introducing the future of drilling" />
            </TextImageWrapper>
          </Inner>
        </Container>
        <Down />
      </Wrapper>
    </BackgroundImage>
  );
}

function Down() {
  function handleDownClick() {
    scrollToElement('#intro', {
      duration: 1000,
    });
  }
  return (
    <button onClick={handleDownClick}>
      <img src={DownSrc} alt="Down" />
    </button>
  );
}

Hero.propTypes = heroTypes;
